<template>
  <div class="disk">
    <aside class="disk-aside">
      <header class="title">
        网盘
      </header>
      <div class="list">
        <div class="ekt-disk">EKT网盘</div>
        <ul>
          <li
            v-for="item in asideList"
            :key="item.key"
            @click="diskKey = item.key"
            :class="{ active: item.key === diskKey }"
          >
            {{ item.str }}
          </li>
        </ul>
      </div>
    </aside>
    <main class="disk-main">
      <keep-alive>
        <components :is="diskKey"></components>
      </keep-alive>
    </main>
  </div>
</template>

<script>
import all from '@/components/task/disk/all';
import my from '@/components/task/disk/my';
import transfer from '@/components/task/disk/transfer';
export default {
  name: 'Disk',
  data() {
    return {
      asideList: [
        {
          key: 'all',
          str: '全部文件',
        },
        {
          key: 'my',
          str: '我的文件',
        },
        {
          key: 'transfer',
          str: '传输列表',
        },
      ],
      diskKey: 'all',
    };
  },
  components: {
    all,
    my,
    transfer,
  },
  methods: {},
};
</script>

<style scoped lang="less">
@import '~@/assets/style/task/disk';
</style>
