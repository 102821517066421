<template>
  <div class="transfer">
    <main class="transfer-main">
      <header>
        <div
          v-for="item in upAndDown"
          :key="item.key"
          @click="upOrDown = item.key"
          :class="{ active: upOrDown === item.key }"
          class="ud"
        >
          {{ item.str }}
        </div>
      </header>
      <main>
        <div class="tip">
          <span>本次共上传20个文件，已成功上传18个文件，失败1个</span>
          <button>清空所有记录</button>
        </div>
        <div class="title">
          <div class="inline">
            <span>名称</span>
          </div>
          <div class="inline">状态</div>
          <div class="inline">日期</div>
          <div class="inline">操作</div>
        </div>
        <div class="fileList" v-show="upOrDown === 'up'">
          <ul>
            <li v-for="(item, index) in upList" :key="index">
              <div class="file name">
                <img
                  src="../../../assets/image/icon_disk/icon_wp_tpwj_70@2x.png"
                  width="24px"
                  v-if="typeFile(item.name_kind) === 0"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_word_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 1"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_excel_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 2"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_ppt_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 3"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_pdf_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 4"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_text_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 5"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_music_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 6"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_video_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 7"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 8"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_wz@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 999"
                />
                <span>{{ item.name_kind }}</span>
              </div>
              <div class="state file">上传完成</div>
              <div class="date file">{{ parseData(item.ctime) }}</div>
              <div class="operation file">111</div>
            </li>
          </ul>
        </div>
        <div class="fileList" v-show="upOrDown === 'down'">
          <ul>
            <li v-for="(item, index) in upList" :key="index">
              <div class="file name">
                <img
                  src="../../../assets/image/icon_disk/icon_wp_tpwj_70@2x.png"
                  width="24px"
                  v-if="typeFile(item.name_kind) === 0"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_word_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 1"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_excel_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 2"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_ppt_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 3"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_pdf_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 4"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_text_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 5"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_music_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 6"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_video_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 7"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 8"
                />
                <img
                  src="../../../assets/image/icon_disk/icon_wp_wz@2x.png"
                  width="24px"
                  v-else-if="typeFile(item.name_kind) === 999"
                />
                <span>{{ item.name_kind }}</span>
              </div>
              <div class="state file">上传完成</div>
              <div class="date file">{{ parseData(item.ctime) }}</div>
              <div class="operation file">111</div>
            </li>
          </ul>
        </div>
      </main>
    </main>
  </div>
</template>

<script>
import { typeFile } from '@/utils/utils';
import { getDiskUploadLis } from '@/service/help';
export default {
  name: 'transferList',
  data() {
    return {
      upAndDown: [
        {
          str: '上传列表',
          key: 'up',
        },
        {
          str: '下载列表',
          key: 'down',
        },
      ],
      upOrDown: 'up',
      upList: [
        {
          ctime: '',
          disk_file_id: '',
          filename: '.1',
          id: '',
          is_upload: '',
          kind: '',
          name_kind: '',
          uid: '',
          url: '',
        },
      ],
    };
  },
  created() {
    getDiskUploadLis().then(res => {
      this.upList = res.data.data;
      console.log(res.data.data);
    });
  },
  methods: {
    typeFile,
    parseData(data) {
      return data.substr(0, 16);
    },
  },
};
</script>

<style scoped lang="less">
@import 'transfer';
</style>
