<template>
  <div class="my">
    <main class="my-main"></main>
  </div>
</template>

<script>
export default {
  name: 'myFile',
  data() {
    return {};
  },

  methods: {},
};
</script>

<style scoped lang="less">
@import 'my';
</style>
