<template>
  <div class="all">
    <header class="all-header">
      <ul>
        <li
          v-for="item in headerList"
          :key="item.key"
          @click="headerClick(item.key)"
          :class="{ active: funKey === item.key }"
        >
          <div :class="item.key" class="ico" />
          <span>{{ item.str }}</span>
        </li>
        <div class="ico image" :class="{ ma: model === 'image' }" @click="model = 'image'" />
        <div class="ico list" :class="{ la: model === 'list' }" @click="model = 'list'" />
      </ul>
    </header>
    <main class="all-main">
      <header>
        <div class="line">
          <div class="ico back-ico" />
          <div class="ico forward-ico" />
          <div class="ico refresh" />
        </div>
        <div class="file-path" v-for="(item, index) in filePath" :key="index">
          <span class="path">{{ item }}</span
          ><span>></span>
        </div>
        <input type="search" placeholder="搜索" />
      </header>
      <div class="title" v-show="model === 'list'">
        <div class="inline" :class="{ select: check }">
          <input type="checkbox" ref="selectAll" v-model="check" @click="selectAll" v-show="checkDom" /><span
            >名称</span
          >
        </div>
        <div class="inline">修改日期</div>
        <div class="inline">类型</div>
        <div class="inline">大小</div>
      </div>
      <div class="title" v-show="model === 'image'">
        <div class="inline" :class="{ select: check }">
          <input type="checkbox" ref="selectAll" v-model="check" @click="selectAll" v-show="checkDom" /><span
            >全选</span
          >
        </div>
      </div>
      <div class="fileList" v-show="model === 'list'">
        <ul>
          <li v-for="(item, index) in file" :key="index" :class="{ select: checked.indexOf(item.name) !== -1 }">
            <div class="file name">
              <input type="checkbox" :value="item.name" v-model="checked" v-show="checkDom" />
              <img
                src="../../../assets/image/icon_disk/icon_wp_tpwj_70@2x.png"
                width="24px"
                v-if="typeFile(item.type) === 0"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_word_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 1"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_excel_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 2"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_ppt_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 3"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_pdf_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 4"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_text_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 5"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_music_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 6"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_video_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 7"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 8"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_wz@2x.png"
                width="24px"
                v-else-if="typeFile(item.type) === 999"
              />
              <span>{{ item.name }}</span>
            </div>
            <div class="date file">{{ item.modDate }}</div>
            <div class="type file">{{ item.type }}</div>
            <div class="size file">{{ item.size }}</div>
          </li>
        </ul>
      </div>
      <div class="fileImage" v-show="model === 'image'">
        <ul>
          <li
            v-for="(item, index) in file"
            :key="index"
            :class="{ select: checked.indexOf(item.name) !== -1 }"
            @click="isSelect(item.name)"
          >
            <input type="checkbox" :value="item.name" v-model="checked" v-show="checkDom" />
            <div class="img">
              <img
                src="../../../assets/image/icon_disk/icon_wp_tpwj_70@2x.png"
                width="58px"
                v-if="typeFile(item.type) === 0"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_word_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 1"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_excel_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 2"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_ppt_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 3"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_pdf_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 4"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_text_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 5"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_music_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 6"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_video_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 7"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_yswj_70@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 8"
              />
              <img
                src="../../../assets/image/icon_disk/icon_wp_wz@2x.png"
                width="58px"
                v-else-if="typeFile(item.type) === 999"
              />
            </div>
            <div class="name">
              <span>{{ item.name }}</span>
            </div>
          </li>
        </ul>
      </div>
    </main>
  </div>
</template>

<script>
import { typeFile } from '@/utils/utils';
import { postDiskFolderLis } from '@/service/help';
export default {
  name: 'allFile',
  data() {
    return {
      headerList: [
        {
          key: 'new',
          str: '新建文件夹',
        },
        {
          key: 'up',
          str: '上传',
        },
        {
          key: 'select',
          str: '多选',
        },
        {
          key: 'download',
          str: '下载',
        },
        {
          key: 'delete',
          str: '删除',
        },
      ],
      file: [
        {
          name: 'app',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js 深入浅出node.js 深入浅出node.js 深入浅出node.js 深入浅出node.js 深入浅出node.js',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        {
          name: 'app(1)',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John(1)',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格(1)',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程(1)',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js(1)',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        {
          name: 'app(2)',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John(2)',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格(2)',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程(2)',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js(2)',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        {
          name: 'app(3)',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John(3)',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格(3)',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程(3)',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js(3)',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        {
          name: 'app(4)',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John(4)',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格(4)',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程(4)',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js(4)',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        {
          name: 'app(6)',
          modDate: '2021-11-30 11:45',
          type: '文件夹',
          size: '',
        },
        {
          name: 'Dear John(6)',
          modDate: '2021-11-30 11:45',
          type: '.mp3',
          size: '794k',
        },
        {
          name: '还珠格格(6)',
          modDate: '2021-11-30 11:45',
          type: '.mp4',
          size: '794k',
        },
        {
          name: 'vue.js教程(6)',
          modDate: '2021-11-30 11:45',
          type: '.docx',
          size: '794k',
        },
        {
          name: '深入浅出node.js(6)',
          modDate: '2021-11-30 11:45',
          type: '.pdf',
          size: '794k',
        },
        /*          {
            ctime: "2021-01-04 17:42:14",
            dad_id: "-1",
            foldername: "jb",
            id: "1",
            kind: "文件夹",
          }*/
      ],
      funKey: '',
      model: 'list',
      checkDom: false, //是否开启多选
      check: false,
      checked: [],
      filePath: ['Ekt网盘', '任务管理'],
    };
  },
  created() {
    postDiskFolderLis().then(res => {
      console.log(res.data.data);
      /*        this.file = res.data.data.folder.concat(res.data.data.file)*/
    });
  },
  methods: {
    typeFile,
    selectAll() {
      if (!this.check) {
        this.checked = [];
        this.file.forEach(item => {
          this.checked.push(item.name);
        });
      } else {
        this.checked = [];
      }
    },
    isSelect(name) {
      if (this.checkDom) {
        let i = this.checked.indexOf(name);
        if (i !== -1) {
          this.checked.splice(i, 1);
        } else {
          this.checked.push(name);
        }
      }
    },
    headerClick(k) {
      if (this.funKey === k) {
        this.funKey = '';
      } else {
        this.funKey = k;
      }
      switch (k) {
        case 'new':
          break;
        case 'up':
          break;
        case 'select':
          this.checkDom = this.funKey === 'select';
          break;
        case 'download':
          break;
        case 'delete':
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import 'all';
</style>
